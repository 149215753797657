import React, { useMemo, useState } from 'react'
import {
  Box,
  Calendar,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Separator,
  Skeleton,
  Subheader,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { DateValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'
import { DaySlotsSingleInterface } from '@src/components/DaySingleSlot/DaySingleSlot'
import { utcToZonedTime } from 'date-fns-tz'
import { formatDate } from '@src/utils/format'
import { getTimeZoneLabel } from '@src/utils/timezones'

const useCalendar = (slots: DaySlotsSingleInterface[], timezone: string) => {
  const [firstSlot] = slots
  const [date, setDate] = useState<DateValue>()
  if (!date && firstSlot) {
    setDate(new Date(firstSlot.id))
  }
  const { disabledDays, events } = useMemo(() => {
    if (!slots.length) {
      return {
        disabledDays: [],
        events: [],
      }
    }
    const disabledDaysArr = [
      {
        // we disable all dates before the first slot
        before: new Date(firstSlot.id),
      },
      // we map all slots to after:before pairs
      // except for last slot, in this case we only want to disable every date after it
      ...slots.map((slot, i) => {
        const next = slots[i + 1]
        const after = new Date(slot.id)
        const before = next ? new Date(next.id) : undefined
        return {
          after,
          before,
        }
      }),
    ]
    const eventsArr = slots.map(s => {
      const eventDate = new Date(s.id)
      return {
        date: eventDate,
        color: Token.color.blue,
      }
    })
    return {
      disabledDays: disabledDaysArr,
      events: eventsArr,
    }
  }, [slots])
  const zonedDate = date && utcToZonedTime(date, timezone).toDateString()
  const dateGroup = zonedDate && slots.find(s => s.id === zonedDate)
  return {
    date,
    dateGroup,
    disabledDays,
    events,
    setDate,
  }
}

type SelectSlotCalendarProps = {
  loading: boolean
  slots: DaySlotsSingleInterface[]
  value?: string | null
  timezone: string
  onChange: (value: string | null) => void
}

export const SelectSlotCalendar = ({
  loading,
  slots,
  timezone,
  value,
  onChange,
}: SelectSlotCalendarProps) => {
  const { date, dateGroup, disabledDays, events, setDate } = useCalendar(slots, timezone)
  if (loading) {
    return (
      <Widget p="s-16">
        <Skeleton />
      </Widget>
    )
  }
  return (
    <Widget p="s-16">
      <Flex maxWidth={600} flexWrap="wrap" justifyContent="space-evenly">
        <Box flex="1 1 auto">
          <Calendar
            disabledDays={disabledDays}
            defaultValue={date}
            // @ts-ignore
            // it says that events doesn't exist for variant="date" but it does
            events={events}
            hideHeader
            value={date}
            variant="date"
            onChange={calendarEvent => {
              setDate(calendarEvent)
              onChange(null)
            }}
          />
        </Box>
        <Box flex="1 1 auto">
          <HStack gap="s-16">
            <Box m="0 auto" hide="*-md">
              <Separator orientation="vertical" />
            </Box>
            <VStack flex="1 1 auto">
              <Subheader variant="nested">
                <Subheader.Title>{!!date && formatDate(date)}</Subheader.Title>
              </Subheader>
              <VStack gap="s-8" height={{ all: 184, md: 344 }} overflow="scroll">
                {dateGroup && (
                  <RadioGroup onChange={onChange} value={value}>
                    {group =>
                      dateGroup.items.map(slot => (
                        <Radio key={slot.id} {...group.getInputProps({ value: slot.id })}>
                          <Text>
                            {getTimeZoneLabel(slot.event_start_datetime, timezone)}
                          </Text>
                        </Radio>
                      ))
                    }
                  </RadioGroup>
                )}
              </VStack>
            </VStack>
          </HStack>
        </Box>
      </Flex>
    </Widget>
  )
}
